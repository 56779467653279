<template lang="pug">
div#app
  router-view
</template>
<script>
export default {
  name: 'App',
  components: {}
}
</script>
<style lang="scss">
html,
body,
#app {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}
</style>
